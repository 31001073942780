import React, { Suspense } from 'react'
import { ChakraProvider, theme } from '@chakra-ui/react'
import { Router, Route } from 'wouter'
import { dynamic } from './lib/modules'

const Home = dynamic(() => import('./pages/home'))
const SizeAndColorPage = dynamic(
  () => import('./pages/contentful/stock/size-and-color')
)

const NumberPage = dynamic(() => import('./pages/contentful/stock/number'))

export const App: React.VFC = () => (
  <ChakraProvider theme={theme}>
    <Suspense fallback={<div>loading...</div>}>
      <Router>
        <Route path="/">
          <Home />
        </Route>
        <Route path="/contentful/stock/size-and-color">
          <SizeAndColorPage />
        </Route>
        <Route path="/contentful/stock/number">
          <NumberPage />
        </Route>
      </Router>
    </Suspense>
  </ChakraProvider>
)
